import React from 'react';
import ReactDOM from 'react-dom/client';
import { CookiesProvider } from 'react-cookie';
// import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Main from './pages/main';

import "./assets/css/BsCustom.css";

// import { ErrorPage404 } from './pages/Error'


const router = createBrowserRouter([

  {
    path: "/*",
    element: <h2>ErrorPage404</h2>
  },
  {
    path: "/",
    // loader: (sessionStorage.getItem("memberEmail")) => { return redirect('/home') },
    // element: {!memberCitizenId ? <Homepage /> : <Navigate to="/" />}
    // element: <Navigate to="/home" />
    element: <Main />
  },
  {
    path: "/:pageView",
    // loader: (sessionStorage.getItem("memberEmail")) => { return redirect('/home') },
    // element: {!memberCitizenId ? <Homepage /> : <Navigate to="/" />}
    // element: <Navigate to="/home" />
    element: <Main />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <CookiesProvider defaultSetOptions={{ path: '/' }}>
    <RouterProvider router={router} />
  </CookiesProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
