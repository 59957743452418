import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";

import MainContent from "../components/MainContent";
import bannerAds from "./Ads400x200.png"

const MainNav = (props) => {
    const match_result = props.match_result;
    return (
        <Navbar
            collapseOnSelect
            className="bg-danger bg-gradient"
            expand="sm"
            data-bs-theme="dark"
            style={{ fontSize: "0.8em" }}
        >
            <Container>
                <Navbar.Brand href="/">SOTH</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link eventKey={"stage"} as={Link} to={"/myscore"}>
                            My Score
                        </Nav.Link>
                        {match_result === "true" ? (
                            <>
                                <NavDropdown
                                    title="Overall"
                                    id="collapsible-nav-dropdown"
                                    data-bs-theme="light"
                                >
                                    <NavDropdown.Item
                                        eventKey={"allPistol"}
                                        as={Link}
                                        to={"/allPistol"}
                                    >
                                        Pistol
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        eventKey={"allPCC"}
                                        as={Link}
                                        to={"/allPCC"}
                                    >
                                        PCC
                                    </NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link
                                    eventKey={"divcls"}
                                    as={Link}
                                    to={"/divcls"}
                                >
                                    Divisions/Class
                                </Nav.Link>
                                <Nav.Link
                                    eventKey={"category"}
                                    as={Link}
                                    to={"/category"}
                                >
                                    Category Leaders
                                </Nav.Link>
                            </>
                        ) : null}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

const MainFooter = () => {
    return (
        <div
            className="sticky-bottom bg-danger bg-gradient py-2 text-light text-center fw-bold"
            style={{ fontSize: "0.9em", letterSpacing: "2px" }}
        >
            IDPA Scoring Presented By SOTH
        </div>
    );
};

const Banner = () => {
    return (
        <>
            <div
                style={{ maxWidth: "500px", marginBottom: "15px" }}
                className="mx-auto"
            >
                <img
                    src={bannerAds}
                    className="w-100 rounded shadow-sm border-2 border-light"
                    alt=""
                />
            </div>
            <div className="row row-cols-1 row-cols-sm-2 g-3 mb-2">
                {/* <div className="col border-1 border-info mx-auto text-center">
                    <img
                        src={bannerAds}
                        className="img-thumbnail"
                        alt=""
                        style={{ maxHeight: "200px", aspectRatio: "2/1" }}
                    />
                </div> */}
                {/* <div className="col border-1 border-info">
        <img
          src={bannerAds}
          className="img-thumbnail w-100"
          alt=""
          style={{maxHeight:"180px", minHeight:"120px", aspectRatio:"2/1"}}
        />
      </div> */}
            </div>
        </>
    );
};

export default function Main() {
    let { pageView } = useParams();
    pageView = pageView ? pageView : "";
    console.log("pageView:", pageView);
    const [cookie, setCookie, removeCookie] = useCookies([
        "matchInfo",
        "shooterNumber",
    ]);

    const [loading, setLoading] = useState(true);
    const [matchInfo, setMatchInfo] = useState([]);

    const MatchInfo = () => {
        // get current match info
        const instance = axios.create({
            baseURL: "https://api.idpasoth.com/stat/",
            headers: {
                Authorization:
                    "Bearer 50d819e4d51dfc02e36bac6f27e9c3a9227f688f",
                method: "matchCurrent",
            },
        });
        instance
            .get()
            .then((res) => {
                console.log("match info loaded: ", res);
                // setMatchInfo(res.data);
                setMatchInfo(res.data);
                setCookie("matchInfo", res.data, { path: "/" });
                setLoading(false);
            })
            .catch((err) => {
                console.log("axios err: ", err);
            });
    };

    const MatchInfoComponent = () => {
        // const matchInfo = props.matchInfo;
        const matchImage = cookie.matchInfo.image[0];
        return (
            <div>
                {/* <p>{JSON.stringify(matchImage)}</p>
              <p>poster:{matchImage.match_img_poster}</p> */}
                <div style={{ maxWidth: "500px" }} className="mx-auto">
                    <img
                        src={
                            "https://idpasoth.com/img/match_banner/" +
                            matchImage.match_img_poster
                        }
                        alt={"matchPoster"}
                        className="w-100 shadow"
                    />
                    <div
                        className="py-3 bg-danger text-center rounded-bottom fs-5 fw-bold"
                        style={{ letterSpacing: "1.5px" }}
                    >
                        {cookie.matchInfo.match_name}
                    </div>
                </div>
                {/* <p>matchInfo: {JSON.stringify(cookie.matchInfo)}</p> */}
            </div>
        );
    };

    const MatchInfoComponentSmall = () => {
        // const matchInfo = props.matchInfo;
        const matchImage = cookie.matchInfo.image[0];
        return (
            <div>
                {/* <p>{JSON.stringify(matchImage)}</p>
              <p>poster:{matchImage.match_img_poster}</p> */}
                <div className="myScoreHolder">
                    <div className="d-flex flex-row">
                        <div className="me-4" style={{ maxWidth: "150px" }}>
                            <img
                                src={
                                    "https://idpasoth.com/img/match_banner/" +
                                    matchImage.match_img_logo
                                }
                                alt={"matchPoster"}
                                // style={{ width: "100%", aspectRatio: "1/1" }}
                                className="rounded rounded-2 w-100 shadow-sm"
                            />
                        </div>
                        <div className="">
                            <p
                                className=""
                                style={{
                                    fontSize: "2.3em",
                                    fontWeight: "500",
                                    letterSpacing: "1px",
                                    marginBottom: "10px",
                                }}
                            >
                                {cookie.matchInfo.match_name}
                            </p>
                            <p
                                className="text-secondary"
                                style={{
                                    fontSize: "1.8em",
                                    fontWeight: "500",
                                    letterSpacing: "1px",
                                    marginBottom: "0",
                                }}
                            >
                                {cookie.matchInfo.match_location}
                            </p>
                            <p
                                className="text-secondary"
                                style={{
                                    fontSize: "1.2em",
                                    fontWeight: "300",
                                    letterSpacing: "1px",
                                }}
                            >
                                {cookie.matchInfo.match_begin}
                            </p>
                            <p
                                className="text-warning"
                                style={{
                                    fontSize: "1.4em",
                                    fontWeight: "500",
                                    letterSpacing: "1px",
                                    marginBottom: "0",
                                }}
                            >
                                {"MD: " + cookie.matchInfo.match_md}
                            </p>
                        </div>
                    </div>
                </div>
                {/* <p>matchInfo: {JSON.stringify(cookie.matchInfo)}</p> */}
            </div>
        );
    };

    useEffect(() => {
        // if (!cookie.matchInfo) {
        MatchInfo();
        removeCookie("shooterNumber");
        // }
        // cookie.matchInfo ? setLoading(false) : setLoading(true);
        console.log("match info:", cookie.matchInfo);
    }, [loading]);

    return cookie.matchInfo ? (
        <>
            <MainNav match_result={cookie.matchInfo.match_result} />
            <div className="bg-dark py-3 text-light">
                {cookie.matchInfo ? (
                    <div className="container" style={{ fontSize: "0.8em" }}>
                        <Banner />
                        <div
                            style={{
                                overflowWrap: "break-word",
                                fontSize: "0.8em",
                            }}
                        >
                            {/* {JSON.stringify(cookie.matchInfo)} */}
                        </div>
                        {pageView !== "" ? (
                            <MatchInfoComponentSmall />
                        ) : (
                            <MatchInfoComponent />
                        )}

                        <MainContent mid={matchInfo ? matchInfo.match_id : null} pageView={pageView ? pageView : ""} />
                    </div>
                ) : null}
            </div>
            <MainFooter />
        </>
    ) : null;
}
