import axios from "axios";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";


export default function MainResult(props) {
  const mid = props.mid;
  const pageView = props.pageView;
  const [overAll, setOverAll] = useState([]);
  const [categories, setCategories] = useState([]);
  const classes = ["DM", "MA", "EX", "SS", "MM", "NV", "UN"];
  const divisions = [
    "SSP",
    "ESP",
    "CCP",
    "CDP",
    "BUG",
    "REV",
    "CO",
    "PCC",
    "SPD",
  ];




  // functions
  function getOverall(mid) {
    const instance = axios.create({
      baseURL: "https://api.idpasoth.com/stat/",
      headers: {
        Authorization: "Bearer 50d819e4d51dfc02e36bac6f27e9c3a9227f688f",
        method: "matchStat",
        mid: mid,
      },
    });
    if (mid && mid > 1) {
      instance
        .get()
        .then((res) => {
          const filterdOverall = (res.data.filter((item) => {
            return item.shooter_DNF === "0" && parseInt(item.shooter_total_score) > 0;
          }));
          setOverAll(filterdOverall);
          console.log("Axios get Overall2: ", overAll);
        })
        .catch((err) => {
          console.log("axios err: ", err);
          // setDataLoaded(false);
        });
    }
  }

  // functions
  function getMatchCategories(mid) {
    const instance = axios.create({
      baseURL: "https://api.idpasoth.com/stat/",
      headers: {
        Authorization: "Bearer 50d819e4d51dfc02e36bac6f27e9c3a9227f688f",
        method: "matchcategory",
        mid: mid,
      },
    });
    if (mid && mid > 1) {
      instance
        .get()
        .then((res) => {
          setCategories(res.data);
          console.log("Axios get Categories: ", categories);
        })
        .catch((err) => {
          console.log("axios err: ", err);
          // setDataLoaded(false);
        });
    }
  }

  function filterData(dataset, div = "", cls = "", cate = "") {
    if (div && div !== "") {
      dataset = dataset.filter((item) => item.shooter_division.toUpperCase() === (div));
    }
    if (cls && cls !== "") {
      dataset = dataset.filter((item) => item.shooter_class.toUpperCase() === (cls));
    }
    if (cate && cate !== "") {
      dataset = dataset.filter((item) => item.shooter_categories ? item.shooter_categories.toLowerCase().includes(cate.toLowerCase()) : null);
    }
    return dataset;
  }

  function filterPCC(dataset, pcc) {
    if (pcc && pcc !== "") {
      return dataset.filter((item) => {
        return pcc === "PCC" ? item.shooter_division.toUpperCase() === ("PCC") : item.shooter_division.toUpperCase() !== ("PCC");
      });
    }
  }

  function GenTblResult(props) {
    const tableName = props.tableName
    const items = props.items
    const div = props.div
    const cls = props.cls
    const cate = props.cate


    return (
      <>
        <div>
          <h5 style={{ marginTop: "40px" }}>
            {tableName === "overall" ? `Result: ${div} (${Object.keys(items).length})` : null}
            {tableName === "divcls" ? `Division: ${div} / ${cls} (${Object.keys(items).length})` : null}
            {tableName === "category" ? `Category: ${cate} (${Object.keys(items).length})` : null}
          </h5>
          <Table
            responsive="md"
            striped
            bordered
            hover
            style={{
              fontSize: "1em",
              width: "100%",
              marginBottom: "20px",
            }}
            className="TblResult"
            data-sticky-header="true"
          >
            <thead>
              <tr className="table-danger tblHeader">
                <th
                  className="text-center text-nowrap"
                  style={{ width: "60px" }}
                >
                  Place
                </th>
                <th className="text-start px-2">Shooter</th>
                <th
                  className="text-center text-nowrap px-2 d-none d-md-table-cell"
                  style={{ width: "100px" }}
                >
                  DIV / CLASS
                </th>
                {/* <th
                  className="text-center text-nowrap px-2 d-none d-md-table-cell"
                  style={{ width: "80px" }}
                >
                  No / SQ
                </th> */}
                {/* <th className="text-center">Categories</th> */}
                <th
                  className="text-center text-nowrap px-2"
                  style={{ width: "80px" }}
                >
                  Time
                </th>
                <th
                  className="text-center text-nowrap px-2"
                  style={{ width: "60px" }}
                >
                  PD
                </th>
                <th
                  className="text-center text-nowrap px-2"
                  style={{ width: "60px" }}
                >
                  PE
                </th>
                <th
                  className="text-center text-nowrap px-2"
                  style={{ width: "60px" }}
                >
                  HNT
                </th>
              </tr>
            </thead>
            <tbody>
              {items ? items.map((item, key) => (
                <tr key={key}>
                  <td className="text-center align-middle">{key + 1}</td>
                  <td className="">
                    {item["shooter_idpa"] ? (
                      <div className="idpaNumber"><Link to={`https://www.idpa.com/members/?type=members&search-word=${item["shooter_idpa"]}&filter_submit=`} target="_blank">{item["shooter_idpa"]}</Link></div>
                    ) : null}
                    {`${item["shooter_firstName"]}  ${item["shooter_lastName"]}`}
                  </td>
                  <td className="text-center text-nowrap align-middle d-none d-md-table-cell">
                    {/* {item["shooter_number"] + " / " + item["shooter_squad"]} */}
                    {item["shooter_division"] + " / " + item["shooter_class"]}
                  </td>
                  {/* <td className="text-center">{item["shooter_categories"]}</td> */}
                  <td className="text-center align-middle">
                    {item["shooter_total_score"]}
                  </td>
                  <td className="text-center align-middle">{item["shooter_PD"]}</td>
                  <td className="text-center align-middle">{item["shooter_PE"]}</td>
                  <td className="text-center align-middle">{item["shooter_HNT"]}</td>
                </tr>
              ))
                : null}
            </tbody>
          </Table>
        </div>
      </>
    );
  }


  // Display
  function StatOverallPistol() {
    console.log("call StatOverPistol");
    return <>
      {/* <p>{JSON.stringify(filterData(overAll, "PCC"))}</p> */}
      <div className="text-center mt-4 text-info fs-3">Match result <span className="text-info-em fs-2">:</span> <span className="text-light">Overall</span></div>
      <GenTblResult tableName="overall" items={filterPCC(overAll, "Pistol")} div={"Pistol"} />
    </>;
  }

  function StatOverallPCC() {
    // const instant =
    // const items = filterData(overAll, "PCC")
    console.log("call StatOverPCC");
    return <>my StatOverPCC
      {/* <p>{JSON.stringify(filterData(overAll, "PCC"))}</p> */}
      <GenTblResult tableName="overall" items={filterData(overAll, "PCC")} div={"PCC"} />
    </>;
  }

  function StatDivCls() {
    console.log("call Stat Div/Cls");

    function genDivisions(items) {
      if (items.length) {
        return divisions.map((div) => filterData(items, div).length ? genClasses(filterData(items, div), div) : null)
      }
    }

    function genClasses(items, div) {
      return <>
        {/* <h5>{div}</h5> */}
        {/* <p>{JSON.stringify(items)}</p> */}

        {div !== "SPD" ? classes.map((cls) => filterData(items, div, cls).length ? <GenTblResult tableName="divcls" items={filterData(items, div, cls).slice(0, 5)} div={div} cls={cls} /> : null) : <GenTblResult tableName="divcls" items={filterData(items, div).slice(0, 5)} div={div} cls={"ALL"} />}
        {/* {div === "SPD" ? classes.map((cls) => filterData(items, div).length ?  : null) : null} */}
      </>
      // classes.map((cls) => <GenTblResult tableName="divcls" items={filterData(overAll, div, cls)} div={div} />)

    }

    return <>
      <div className="text-center mt-4 text-info fs-3">Result <span className="text-info-em fs-2">:</span> <span className="text-light">Division / Class</span></div>
      {genDivisions(overAll)}</>;
  }

  function StatCategory() {
    if (categories && overAll.length) {
      return <>
        <div className="text-center mt-4 text-info fs-3">Result <span className="text-info-em fs-2">:</span> <span className="text-light">Categories</span></div>
        {categories.map((cate) => {
          const items = overAll.filter((item) => {
            return item.shooter_categories !== null ? item.shooter_categories.toLowerCase().includes(cate.category) : null
          })
          return <GenTblResult key={cate.category} tableName="category" items={items.slice(0, 10)} cate={cate.category.toUpperCase()} />
          // console.log("call StatCategory2", items);
        })}
      </>
    }
  }

  useEffect(() => {
    // if (pageView !== "") {
    pageView === "category" && getMatchCategories(mid)
    getOverall(mid);
    // }
    // eslint-disable-next-line
  }, [pageView, mid])

  return <>

    {pageView === "allPCC" ? <StatOverallPCC /> : null}
    {pageView === "allPistol" ? <StatOverallPistol /> : null}
    {pageView === "divcls" ? <StatDivCls /> : null}
    {pageView === "category" ? <StatCategory /> : null}
  </>
}
