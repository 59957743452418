import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import axios from "axios";

import MainResult from "./MainResult";

export default function MainContent(props) {
    const mid = props.mid;
    const paramPageView = props.pageView;
    // console.log("main paramPageView:", paramPageView);
    // console.log("type paramPageView:", typeof paramPageView);
    // console.log("val paramPageView:", paramPageView);
    const navigate = useNavigate();
    const [cookie, setCookie] = useCookies(["matchInfo", "shooterNumber"]);
    const [shooterNumber, setShooterNumber] = useState(null);
    const [shooterScore, setShooterScore] = useState([]);
    const [shooterStage, setShooterStage] = useState([]);
    const [loading, setLoading] = useState(true);

    const ShooterNumberHandleSubmit = (event) => {
        event.preventDefault();
        // console.log("shooter number:", shooterNumber);
        setCookie("shooterNumber", event.target.inputShooterNumber.value, {
            path: "/myscore",
        });
        getShooterScore(mid, event.target.inputShooterNumber.value);
        navigate("/myscore", { replace: false });
    };

    useEffect(() => {
        if (paramPageView === "myscore") {
            if (cookie.shooterNumber) {
                setShooterNumber(cookie.shooterNumber);
                console.log("cookie shooterNumber:", cookie.shooterNumber);
                getShooterScore(mid, cookie.shooterNumber);
            } else {
                console.log("cookie shooterNumber fail:", cookie.shooterNumber);
                // navigate("/myscore/b", { replace: true });
            }
        }
    }, []);

    function ShooterForm() {
        console.log("call ShooterForm");
        return (
            <div className="myFormHolder">
                <div className="">
                    <h6>Enter the Shooter Number</h6>
                    <Form
                        // className="container"
                        noValidate
                        onSubmit={ShooterNumberHandleSubmit}
                    >
                        <InputGroup className="mb-3">
                            <Form.Control
                                id="inputShooterNumber"
                                name="inputShooterNumber"
                                // size="sm"
                                placeholder="000"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                maxLength={"3"}
                                style={{ textAlign: "center" }}
                            />
                            <Button
                                type="submit"
                                // size="sm"
                                variant="outline-danger"
                                id="button-addon2"
                            >
                                View Score
                            </Button>
                        </InputGroup>
                    </Form>
                </div>
            </div>
        );
    }

    const getShooterScore = (mid, shooterNumber) => {
        console.log("axos shooterNumber: ", JSON.stringify(shooterNumber));
        const instance = axios.create({
            baseURL: "https://api.idpasoth.com/stat/",
            headers: {
                Authorization:
                    "Bearer 50d819e4d51dfc02e36bac6f27e9c3a9227f688f",
                method: "shooterScore",
                mid: mid,
                shooter_number: shooterNumber,
            },
        });
        instance
            .get()
            .then((res) => {
                console.log("shooter score loaded: ", res);
                // setMatchInfo(res.data);
                setShooterNumber(shooterNumber);
                setShooterScore(res.data);
                setShooterStage(res.data.stage_score);
                setLoading(false);
                // setLoading(false);
            })
            .catch((err) => {
                console.log("axios err: ", err);
            });
    };

    function StageTable() {
        return (
            <Table
                responsive
                // striped
                // hover
                bordered
                variant="dark"
                style={{ fontSize: "1em", marginBottom: "0" }}
            >
                <thead>
                    <tr
                        className="text-center table-secondary"
                        style={{ fontSize: "1.2em" }}
                    >
                        <th style={{ minWidth: "70px" }}>Stage</th>
                        <th style={{ minWidth: "70px" }}>Final</th>
                        <th style={{ minWidth: "50px" }}>PD</th>
                        <th style={{ minWidth: "50px" }}>PE</th>
                        <th style={{ minWidth: "50px" }}>HNT</th>
                        <th style={{ minWidth: "70px" }}>Time</th>
                    </tr>
                </thead>
                <tbody>
                    {shooterStage.map((row, key) => (
                        <tr
                            key={key}
                            className="text-center hoverPrimary"
                            style={{ fontSize: "1.1em" }}
                        >
                            <td style={{ whiteSpace: "unset" }}>
                                {row.score_stage_number}
                            </td>
                            <td
                                style={{ whiteSpace: "unset" }}
                                className="py-2"
                            >
                                {row.score_stage_time}
                            </td>
                            <td style={{ whiteSpace: "unset" }}>
                                {row.score_stage_PD}
                            </td>
                            <td style={{ whiteSpace: "unset" }}>
                                {row.score_stage_PE}
                            </td>
                            <td style={{ whiteSpace: "unset" }}>
                                {row.score_stage_HNT}
                            </td>
                            <td style={{ whiteSpace: "unset" }}>
                                {row.score_stage_string_1}
                            </td>
                        </tr>
                    ))}
                    <tr
                        className="text-center table-success fw-bold"
                        style={{ fontSize: "1.2em" }}
                    >
                        <td style={{ whiteSpace: "unset" }}>Total</td>
                        <td style={{ whiteSpace: "unset" }} className="py-2">
                            {shooterScore.shooter_total_score}
                        </td>
                        <td style={{ whiteSpace: "unset" }}>
                            {shooterScore.shooter_PD}
                        </td>
                        <td style={{ whiteSpace: "unset" }}>
                            {shooterScore.shooter_PE}
                        </td>
                        <td style={{ whiteSpace: "unset" }}>
                            {shooterScore.shooter_HNT}
                        </td>
                        <td style={{ whiteSpace: "unset" }}>
                            {shooterScore.total_stage_string_1}
                        </td>
                    </tr>
                </tbody>
            </Table>
        );
    }

    function MyScore() {
        console.log("call My score");
        console.log("shooterNumber:", shooterNumber);

        return shooterScore ? (
            <>
                {/* my score */}
                {/* {"Stage_score:" + JSON.stringify(shooterStage)} */}
                <div id="shooterInfo" className="myScoreHolder">
                    {/* <div>{shooterScore.shooter_number}</div>
          <div>{shooterScore.shooter_idpa}</div>
          <div>{shooterScore.shooter_firstName}</div>
          <div>{shooterScore.shooter_lastName}</div>
          <div>{shooterScore.shooter_squad}</div>
          <div>{shooterScore.shooter_division}</div>
          <div>{shooterScore.shooter_class}</div>
          <div>{shooterScore.shooter_categories}</div>
          <div>{shooterScore.shooter_completed}</div>
          <div>{shooterScore.shooter_total_score}</div>
          <div>{shooterScore.shooter_PD}</div>
          <div>{shooterScore.shooter_HNT}</div>
          <div>{shooterScore.shooter_lastupdate}</div> */}
                    <div>
                        <span className="text-light fs-5">#</span>
                        <span className="text-warning me-3 fs-5">{`${shooterScore.shooter_number}`}</span>
                        <span className="text-light me-3 fs-4">{`${shooterScore.shooter_firstName}  ${shooterScore.shooter_lastName}`}</span>
                        {shooterScore.shooter_idpa ? (
                            <span className="text-warning me-3 fs-6">{`[${shooterScore.shooter_idpa}]`}</span>
                        ) : null}
                    </div>
                    <div>
                        <span className="text-light fs-5">SQD:</span>
                        <span className="text-warning me-3 fs-5">{`${shooterScore.shooter_squad}`}</span>
                        <span className="text-light me-3 fs-4">{`${shooterScore.shooter_division} / ${shooterScore.shooter_class}`}</span>
                        {shooterScore.shooter_categories ? (
                            <span className="text-light me-3 fs-7">{`[${shooterScore.shooter_categories}]`}</span>
                        ) : null}
                    </div>
                    <StageTable />
                </div>
            </>
        ) : null;
    }

    function HomePage() {
        console.log("call Homepage");
        return <>Homepage</>;
    }

    if (!paramPageView || paramPageView === "") {
      console.log("shooter result:", cookie.matchInfo.shooter_result);
      if (cookie.matchInfo.shooter_result === "true") {
          return ShooterForm();
      } else {
          return HomePage();
      }
    } else if (paramPageView === "myscore") {
        // if (!cookie.shooterNumber) {

        if (!shooterNumber || shooterNumber === null || shooterNumber === "") {
            // if (!cookie.shooterNumber || cookie.shooterNumber === null || cookie.shooterNumber === "") {
            // return MyScore();
            return ShooterForm();
        } else {
            return MyScore();
        }
    } else if (paramPageView !== "") {
        // return StatOverPistol();
        return <MainResult pageView={paramPageView} mid={mid} />;
    } 

    //   return <></>;
}
